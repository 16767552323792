import Vue from 'vue'

const _sortNumber = function (a, b, attr) {
  if (a[attr] && b[attr]) {
    return +a[attr] - +b[attr]
  } else if (a[attr]) {
    return 1
  } else if (b[attr]) {
    return -1
  }
  return 0
}

const _sortStringAttr = function (a, b, attr) {
  if (a[attr] && b[attr]) {
    return a[attr].localeCompare(b[attr])
  } else if (a[attr]) {
    return 1
  } else if (b[attr]) {
    return -1
  }
  return 0
}

const _sortDate = function (a, b, attr) {
  const { moment } = Vue
  const date_a = moment(a[attr], 'DD/MM/YYYY')
  const date_b = moment(b[attr], 'DD/MM/YYYY')
  if (date_a && date_b) {
    return date_a - date_b
  }
}

const _sortDateTime = function (a, b, attr) {
  const { moment } = Vue
  const date_a = moment(a[attr], 'DD/MM/YYYY HH:mm:ss')
  const date_b = moment(b[attr], 'DD/MM/YYYY HH:mm:ss')
  if (date_a && date_b) {
    return date_a - date_b
  }
}

export const sortName = (a, b) => _sortStringAttr(a, b, 'name')

export const sortInstance = (a, b) => _sortStringAttr(a, b, 'instance')

export const sortInitialLevel = (a, b) => _sortNumber(b, a, 'initial_level')

export const sortTargetLevel = (a, b) => _sortNumber(b, a, 'target_level')

export const sortReductionLevel = (a, b) => _sortNumber(b, a, 'reduction_level')

export const sortValue = (a, b) => _sortNumber(a, b, 'value')

export const sortLowValue = (a, b) => _sortNumber(a, b, 'low_value')

export const sortOrder = (a, b) => _sortNumber(a, b, 'order')

export const sortIdentifier = (a, b) => {
  if (a.identifier && b.identifier) {
    return a.identifier.localeCompare(b.identifier)
  } else if (a.identifier) {
    return 1
  } else if (b.identifier) {
    return -1
  }
  return 0
}

export const sortText = (a, b) => _sortStringAttr(a, b, 'text')

export const sortPublicIdentifier = (a, b) => {
  if (a.public && !b.public) {
    return 1
  } else if (b.public && !a.public) {
    return -1
  } else if (a.identifier && b.identifier) {
    return _sortStringAttr(a, b, 'identifier')
  } else {
    return _sortStringAttr(a, b, 'name')
  }
}

export const sortPublic = (a, b) => {
  if (a.public && !b.public) {
    return 1
  } else if (b.public && !a.public) {
    return -1
  } else {
    return _sortStringAttr(a, b, 'name')
  }
}

export const sortDate = (a, b) => _sortDate(a, b, 'date')

export const sortDateCreated = (a, b) => _sortDate(a, b, 'date_created')

export const sortDateTime = (a, b, attr = 'timestamp') => _sortDateTime(a, b, attr)

export const sortControlLevel = (a, b) => _sortNumber(a, b, 'control_order')

export const sortDashboardGroup = (a, b) => _sortNumber(a, b, 'dashboard_order')

export const sortControl = (a, b) => _sortNumber(a, b, 'control_group_order')

export const sortControlGroup = (a, b) => _sortNumber(a, b, 'policy_order')

export const sortIntIdentifier = (a, b) => {
  const nums1 = a.identifier.split('.')
  const nums2 = b.identifier.split('.')

  for (let i = 0; i < nums1.length; i++) {
    if (nums2[i]) {
      // assuming 5..2 is invalid
      if (nums1[i] !== nums2[i]) {
        return nums1[i] - nums2[i]
      } // else continue
    } else {
      return 1 // no second number in b
    }
  }
  return -1 // was missing case b.len > a.len
}
